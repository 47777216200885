import React, { useState, useEffect } from "react";
import { helprService } from "../../service/api.service";
import { ThreeDotSpinner } from "../loader";
import swal from "sweetalert";
import { Col, Container, Modal, Row, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";

export const ContactList = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [search, setSearch] = useState("");
  const [detailModal, setDetailModal] = useState(false);
  const [detailData, setDetailData] = useState({});

  useEffect(() => {
    contact_list(page, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function contact_list(page, search) {
    try {
      // setLoader(true);
      const params = {
        page: page,
        search: search,
      };
      const response = await helprService.contactUs(params);
      if (response?.status === 200) {
        setData(response?.data?.data);
        setLoader(false);
        setTotalItems(response?.data?.total_count);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        setLoader(false);
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);
        swal({
          icon: "error",
          text: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }

  /* pagination */
  function handlePageChange(pageNumber) {
    setPage(pageNumber);
    contact_list(pageNumber, search);
  }

  /* search */
  function handleUpdateSearch(search) {
    setSearch(search);
    setPage(1);
    contact_list(page, search);
  }

  const openDetailModal = (data) => {
    setDetailData(data);
    setDetailModal(true);
  };

  return (
    <>
      <div className="common-spacing">
      <div className="dashboard-main-area">
        {loader ? <ThreeDotSpinner /> : ""}
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={11}>
                <Row className="justify-content-between">
                  <Col md={4} className="d-flex ">
                    <h2 className="align-self-center m-0">
                      Contact Management
                    </h2>
                  </Col>

                  <Col md={3} className="search-area">
                    <label>Search</label>
                    <form
                      autoComplete="off"
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        type="text"
                        className="form-control inner-input"
                        placeholder="Search by email or name"
                        onChange={(e) => handleUpdateSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        onClick={() => contact_list(page, search)}
                      ></i>
                      <i className="fa fa-close d-none" aria-hidden="true"></i>
                    </form>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={11}>
                <div className="audit-request-box contact-list-area">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Subject</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        data?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{(page - 1) * 10 + (index + 1)}</td>
                              <td>{data?.name}</td>
                              <td>{data?.email}</td>
                              <td>{data?.subject}</td>
                              {data?.message.length > 50 ? (
                                <td>
                                  {data?.message.slice(0, 50)}
                                  <span
                                    href="#"
                                    className="view-more"
                                    onClick={() => openDetailModal(data)}
                                    role="button"
                                  >
                                    ... view more
                                  </span>
                                </td>
                              ) : (
                                <td>{data?.message}</td>
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-dat-found-box">
                          <td colSpan={10}>
                            <img
                              src={
                                require("../../assets/images/no-data.svg")
                                  .default
                              }
                              alt="da"
                            />
                            <h3 className="text-center text-light">
                              No data found
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {data.length > 0 && totalItems > 10 ? (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
          <Modal
            className="contact-detail-popup"
          show={detailModal}
          onHide={() => {
            setDetailModal(false);
            setDetailData({});
          }}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <p>Name: <span>{detailData?.name}</span></p>
            <p>Email: <span>{detailData?.email}</span></p>
            <p>Subject: <span>{detailData?.subject}</span></p> */}
            <p><span>{detailData?.message}</span></p>
          </Modal.Body>
        </Modal>
      </div>
        </div>
    
    </>
  );
};
