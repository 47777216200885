import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "../loader";
import { useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { helprService } from "../../service/api.service";
import swal from "sweetalert";

export const ExcessiveList = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [data, setData] = useState([]);
  const [tokenModal, setTokenModal] = useState(false);
  const [usageLimit, setUsageLimit] = useState(0);
  const [newLimit, setNewLimit] = useState(0);

  const viewTooltip = () => (
    <Tooltip id="tooltip">
      <strong>View</strong>
    </Tooltip>
  );

  useEffect(() => {
    getCurrentLimit();
  }, [tokenModal]);

  useEffect(() => {
    userList(page, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getCurrentLimit() {
    try {
      const response = await helprService.getTokenLimit();
      setUsageLimit(response?.data?.data);
      setNewLimit(response?.data?.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);
        swal({
          icon: "error",
          text: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }

  async function changeTokenLimit() {
    try {
      await helprService.setTokenLimit({
        token_limit: newLimit,
      });
      setTokenModal(false);
      setPage(1);
      setSearch("");
      userList(1, "");
    } catch (error) {
      if (error?.response?.status === 401) {
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);
        swal({
          icon: "error",
          text: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }

  const userList = (page, search) => {
    // setLoader(true);
    helprService
      .excessiveUser({ page: page, search: search })
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        setTotalItems(response?.data?.total_count);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setLoader(false);
          swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(
            () => {
              localStorage.clear();
              window.location.href = "/";
              console.log("OK button clicked after error alert");
            }
          );
        } else {
          setLoader(false);
          swal({
            icon: "error",
            text: error?.response?.data?.detail
              ? error?.response?.data?.detail
              : error?.message,
            button: "OK",
          }).then((value) => {
            console.log("OK button clicked after error alert");
          });
        }
      });
  };

  const handleUpdateSearch = (search) => {
    setSearch(search);
    setPage(1);
    userList(1, search);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    userList(pageNumber, search);
  };

  return (
    <>
      <div className="common-spacing">
        <div className="dashboard-main-area">
          {loader ? <ThreeDotSpinner /> : ""}

          <Container fluid>
            <div className="dashboard-area-heading management-heading">
              <Row className="row justify-content-center align-items-center mb-3">
                <Col md={11}>
                  <Row className="justify-content-between">
                    <Col md={4} className="d-flex ">
                      <h2 className="align-self-center m-0">
                        Excessive Usage Customers
                      </h2>
                    </Col>

                    <Col md={6} className="search-area">
                      <div className="excessive-usge-top-area">
                        <div className="excessive-usge-top-area-left">
                          <label>Token Limit: {usageLimit}</label>
                          <Button
                            variant="primary"
                            onClick={() => setTokenModal(true)}
                          >
                            Change Token Limit
                          </Button>
                        </div>
                        <div className="excessive-search-area">
                          <label>Search</label>
                          <form
                            autoComplete="off"
                            onSubmit={(event) => {
                              event.preventDefault();
                            }}
                          >
                            <input
                              type="text"
                              className="form-control inner-input"
                              placeholder="Search by email or name"
                              onChange={(e) =>
                                handleUpdateSearch(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (!e.target.value && e.code === "Space") {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <i
                              className="fa fa-search"
                              aria-hidden="true"
                              onClick={() => userList(page, search)}
                            ></i>
                            <i
                              className="fa fa-close d-none"
                              aria-hidden="true"
                            ></i>
                          </form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="pb-4 justify-content-center">
                <Col md={11}>
                  <div className="audit-request-box">
                    <Table responsive="sm" className="UserListTable">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Today's Token Usage</th>
                          <th>This Month Token Usage</th>
                          <th>Total Token Usage</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{(page - 1) * 10 + (index + 1)}</td>
                                <td>
                                  {data?.name ? <>{data?.name} </> : <>NA</>}
                                </td>
                                <td>
                                  {data?.email ? <>{data?.email}</> : <>NA</>}
                                </td>
                                <td>{data?.today_token_usage ? <>{data?.today_token_usage}</> : <>0</>}</td>
                                <td>{data?.this_month_token_usage}</td>
                                <td>{data?.total_token_usage}</td>
                                <td className="user-tooltip">
                                  <span
                                    role="button"
                                    onClick={() => navigate(`/user/${data.id}`)}
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={viewTooltip()}
                                    >
                                      <i
                                        className="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </span>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="no-dat-found-box">
                            <td colSpan={10}>
                              <img
                                src={
                                  require("../../assets/images/no-data.svg")
                                    .default
                                }
                                alt="da"
                              />
                              <h3 className="text-center text-light">
                                No data found
                              </h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {data.length > 0 && totalItems > 10 ? (
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItems}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                      />
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Modal
          show={tokenModal}
          onHide={() => setTokenModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="excessive-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Set Token Limit
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Enter Token Limit</label>
            <input
              type="text"
              value={newLimit}
              placeholder="Enter Token Limit"
              maxLength={15}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setNewLimit(e.target.value);
                }
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => changeTokenLimit()}>Set Limit</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
