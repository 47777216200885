import React from "react";
import { Container, Dropdown, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { helprService } from "../service/api.service";
const Header = () => {
  const navigate = useNavigate();
  /* Logout */

  async function Logout() {
    const params = {}
    try {
      const response = await helprService.signOut(params);
      if (response.status === 200) {
        console.log("response------>", response);
        // swal("Success",response.data.detail,"success");
        localStorage.clear()
        navigate("/")

      }
    }
    catch (error) {
      console.log("error", error);
    }
  }




  return (
    <>
      <div className="admin-header">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Brand href="#">
              {/* <div className="logo" onClick={(e) => navigate("/user-list")}>
                <img src={require("../assets/images/logo.png")} alt="" />
              </div> */}
            </Navbar.Brand>
              <div className="logout-btn">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={require("../assets/images/user.png")} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => { navigate("/change-password") }}
                    >
                      <i className="fa fa-lock" aria-hidden="true"></i>
                      Change Password
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        swal({
                          title: 'Are you sure?',
                          text: 'you want to logout.',
                          icon: 'warning',
                          buttons: ['Cancel', 'Yes, logout'],
                          dangerMode: true,
                        }).then((confirmed) => {
                          if (confirmed) {
                            Logout()
                            // localStorage.clear();
                            // navigate("/");

                          }
                        })
                      }}

                    >
                      <i className="fa fa-sign-out" aria-hidden="true"></i>
                      Sign Out
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
          </Container>
        </Navbar>



      </div>
    </>
  );
};
export default Header;
