import axios from "axios";
import { getItem } from '../util/localStorage'
import { apiUrl } from "../config/config";


export const helprService = {
    login,
    userStatus,
    UserList,
    userDetail,
    userSubscription,
    userStipeSubscription,
    contactUs,
    signOut,
    changePassword,
    setTokenLimit,
    getTokenLimit,
    excessiveUser,
    cancelRefundSubscription,
    cancelStripeRefundSubscription,
    getConfig,
    updateConfig,
}


const headers = () => {
    let token = getItem("accessToken")
    return { headers: { 'Authorization': `Token ${token}` } }
};

async function UserList(params) {
    return await axios.get(`${apiUrl}/user/?page=${params.page}&search=${params.search}`, headers())
}
async function userStatus(params) {
    return await axios.put(`${apiUrl}/user/${params.userId}/disable/`,params,headers())
}

async function changePassword(data) {
    return await axios.post(`${apiUrl}/change-password/`, data, headers())
}
async function login(data) {
    return await axios.post(`${apiUrl}/login/`, data);
}

async function signOut(data) {
    return await axios.post(`${apiUrl}/logout/`, data, headers())
}

async function userDetail(id) {
    return await axios.get(`${apiUrl}/user/${id}/`, headers())
}

async function userSubscription(id) {
    return await axios.get(`${apiUrl}/get-subscription/?subscription_id=${id}`, headers())
}

async function userStipeSubscription(id) {
    return await axios.get(`${apiUrl}/get-stripe-subscription/?subscription_id=${id}`, headers())
}

async function setTokenLimit(data) {
    return await axios.post(`${apiUrl}/token-limit/`, data, headers())
}

async function getTokenLimit() {
    return await axios.get(`${apiUrl}/token-limit/`, headers())
}

async function excessiveUser(params) {
    return await axios.get(`${apiUrl}/excessive-usage/?page=${params.page}&search=${params.search}`, headers())
}

async function contactUs(params) {
    return await axios.get(`${apiUrl}/contact-us/?page=${params.page}&search=${params.search}`, headers())
}

async function cancelRefundSubscription(params) {
    return await axios.post(`${apiUrl}/cancel-refund-subscription/`, params, headers())
}

async function cancelStripeRefundSubscription(params) {
    return await axios.post(`${apiUrl}/cancel-refund-stripe-subscription/`, params, headers())
}

async function getConfig(name) {
    return await axios.get(`${apiUrl}/config/?name=${name}`, headers())
}

async function updateConfig(data) {
    return await axios.post(`${apiUrl}/config/`, data, headers())
}
