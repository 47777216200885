import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ThreeDotSpinner } from "../loader";
import userImg from "../../assets/images/byDefaultUser.png"
import { helprService } from "../../service/api.service";
import swal from "sweetalert";
import moment from "moment";
import Swal from "sweetalert2";

export const UserDetail = () => {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [loader, setLoader] = useState(false);
  const params = useParams();

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getUserData = async () => {
  //   try {
  //     setLoader(true);
  //     const response = await helprService.userDetail(params.id)
  //     setData(response?.data?.data);
  //     if (response?.data?.data?.subscription_id) {
  //       await getSubscription(response?.data?.data?.subscription_id);
  //     } else {
  //       setSubscription(null);
  //     }
  //     setLoader(false);
  //   } catch (error) {
  //     setLoader(false)
  //     if (error?.response?.status === 401) {
  //       swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
  //         localStorage.clear()
  //         window.location.href = "/";
  //         console.log('OK button clicked after error alert');
  //       });
  //     } else {
  //       swal({ icon: 'error', text: error?.response?.data?.detail ? error?.response?.data?.detail : error?.detail, button: "OK" }).then(
  //         (value) => {
  //           console.log('OK button clicked after error alert');
  //         }
  //       );
  //     }
  //   }
  // }

  // const getSubscription = async (subscription_id) => {
  //   try {
  //     const response = await helprService.userSubscription(subscription_id)
  //     setSubscription(response?.data?.data);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // }

  const getUserData = async () => {
    try {
      setLoader(true);
      const response = await helprService.userDetail(params.id)
      setData(response?.data?.data);
      if (response?.data?.data?.stripe_subscription_id) {
        await getSubscription(response?.data?.data?.stripe_subscription_id);
      } else {
        setSubscription(null);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false)
      if (error?.response?.status === 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        swal({ icon: 'error', text: error?.response?.data?.detail ? error?.response?.data?.detail : error?.detail, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }
  }

  const getSubscription = async (subscription_id) => {
    try {
      const response = await helprService.userStipeSubscription(subscription_id)
      console.log("subsdata",response?.data?.data)
      setSubscription(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  // const cancelSubscription = async () => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     cancelButtonText: 'Dismiss',
  //     confirmButtonText: 'Yes, Cancel it!'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       setLoader(true);
  //       const payload = {
  //         subscription_id: data?.subscription_id,
  //         refund: subscription?.status === "trialing" ? false : true
  //       }
  //       helprService.cancelRefundSubscription(payload).then((response) => {
  //         setLoader(false);
  //         swal({ icon: 'success', text: response?.data?.detail, button: "OK" }).then(
  //           (value) => {
  //             console.log('OK button clicked after success alert');
  //           }
  //         );
  //         setTimeout(() => {
  //           getUserData();
  //         }, 2000)
  //       }).catch((error) => {
  //         setLoader(false)
  //         if (error?.response?.status === 401) {
  //           swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
  //             localStorage.clear()
  //             window.location.href = "/";
  //             console.log('OK button clicked after error alert');
  //           });
  //         } else {
  //           swal({ icon: 'error', text: error?.response?.data?.detail ? error?.response?.data?.detail : error?.detail, button: "OK" }).then(
  //             (value) => {
  //               console.log('OK button clicked after error alert');
  //             }
  //           );
  //         }
  //         console.log("error", error);
  //       })
  //     }
  //   })
  // }

  const cancelSubscription = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Dismiss',
      confirmButtonText: 'Yes, Cancel it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        const payload = {
          subscription_id: data?.stripe_subscription_id,
          refund: subscription?.status === "trialing" ? false : true
        }
        helprService.cancelStripeRefundSubscription(payload).then((response) => {
          setLoader(false);
          swal({ icon: 'success', text:'The subscription has been cancelled successfully.', button: "OK" }).then(
            (value) => {
              console.log('OK button clicked after success alert');
            }
          );
          setTimeout(() => {
            getUserData();
          }, 2000)
        }).catch((error) => {
          setLoader(false)
          if (error?.response?.status === 401) {
            swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
              localStorage.clear()
              window.location.href = "/";
              console.log('OK button clicked after error alert');
            });
          } else {
            swal({ icon: 'error', text:"Unable to cancel the subscription at this time. Please try again later.", button: "OK" }).then(
              (value) => {
                console.log('OK button clicked after error alert');
              }
            );
          }
          console.log("error", error);
        })
      }
    })
  }
  
  return (
    <>
      <div className="common-spacing">
        <div className="dashboard-main-area">
          {loader === true ? <ThreeDotSpinner /> : ""}
          <Container fluid>
            <div className="dashboard-area-heading management-heading">

              <Row className="row justify-content-center align-items-center mb-3">
                <Col md={11}>
                  <div className="back-button detail-back-btn" onClick={() => navigate(-1)}><i className="fa fa-arrow-left" aria-hidden="true" /> Back</div>
                  <Row className="justify-content-between">
                    <Col md={4} className="d-flex ">
                      <h2 className="align-self-center m-0">User Detail</h2>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="pb-4 user-detail-area">
                <Col md={11}>
                  <div className="user-detail">

                    <div className="user-info-outer">
                      {data?.profile_image ? <img src={data?.profile_image} alt="profile_photo" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="profile_photo" height={50} width={50} className="rounded-circle" />}
                      <div className="user-info-right">
                        <p>Name: <span>{data?.name ? data?.name : "NA"}</span></p>
                        <p>Email: <span>{data?.email}</span></p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                {subscription && Object.keys(subscription).length > 0 ?
                    <div className="my-plan-outer">
                    {subscription?.status === "trialing" && <div className='subscription-vector-outer'>
                      <div className='subscription-vector'>
                        <img src={require("../../assets/images/subscription-vector.png")} alt='img' />
                      </div>
                      <p>7 Days Free Trial Period</p>
                    </div>}
                    <div className="setting-pricing-area">
                      <div className={`setting-pricing-head-area${subscription?.status !== "trialing" ? " no-plan-head": ""}`}>
                        <div className="setting-pricing-head-left">
                          {/* <h6>{subscription?.items[0]?.price?.billing_cycle?.frequency} {subscription?.items[0]?.price?.billing_cycle?.interval}</h6> */}
                          {/* <p>${subscription?.items[0]?.price?.unit_price?.amount / 100}</p> */}
                          <h6>1 {subscription?.items?.data[0]?.plan?.interval}</h6>
                          <p>${subscription?.items?.data[0]?.plan?.amount / 100}</p>
                        </div>
                        <div className="setting-pricing-head-left per-day-cost">
                          <h3>$0.66 <span>Per Day </span></h3>
                          {/* <p>Active Till: {moment(subscription?.current_billing_period?.ends_at).format("MMM DD YYYY")}</p> */}
                          <p>Active Till: {moment(subscription?.current_period_end * 1000).format("MMM DD YYYY")}</p>
                        </div>

                      </div>
                      <div className="setting-pricing-body">
                        <ul>
                          <li><img src={require("../../assets/images/check.png")} alt="img" />Unlimited access to text chat</li>
                          <li><img src={require("../../assets/images/check.png")} alt="img" />Generate unlimited images</li>
                          <li><img src={require("../../assets/images/check.png")} alt="img" />Upload PDFs and ask unlimited questions</li>
                          <li><img src={require("../../assets/images/check.png")} alt="img" />Add links and ask unlimited questions</li>
                        </ul>
                        {/* {subscription?.next_billed_at ? <Button onClick={() => cancelSubscription()} variant="unset" className="upload">Cancel Subscription</Button>: <p className="sub-cancel">Subscription Cancelled</p>} */}
                        {subscription?.cancel_at_period_end=== false? <Button onClick={() => cancelSubscription()} variant="unset" className="upload">Cancel Subscription</Button>: <p className="sub-cancel">Subscription Cancelled</p>}
                      </div>
                    </div>

                  </div>
                :
                    <div className="no-plan-area">
                      <img src={require("../../assets/images/no-plan.png")} alt="img" />
                      <p>No Active Plan</p>
                    </div>}

          
                </Col>
              </Row>

            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
