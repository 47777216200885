import { helprService } from "../../service/api.service";
import userImg from "../../assets/images/byDefaultUser.png"
import Switch from "react-switch";
import 'react-loading-skeleton/dist/skeleton.css'
import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ThreeDotSpinner } from "../loader";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import swal from "sweetalert";;

export const UsersList = () => {

  const getTooltip = (isActive) => (
    <Tooltip id="tooltip">
      <strong>{isActive ? 'Enable' : 'Disable'}</strong>
    </Tooltip>
  );
  const viewTooltip = () => (
    <Tooltip id="tooltip">
      <strong>View</strong>
    </Tooltip>
  );
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1)
  const [enableGemini, setEnableGemini] = useState(false);

  useEffect(() => {
    user_list(page, search)
    getGeminiValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getGeminiValue() {
    try {
      const response = await helprService.getConfig("gemini");
      setEnableGemini(response?.data?.data === "yes" ? true : false);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function updateGeminiConfig(checked) {
    try {
      const params = {
        name: "gemini",
        value: checked ? "yes" : "no"
      }
      const response = await helprService.updateConfig(params);
      if (response?.status === 200) {
        setEnableGemini(!enableGemini)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function user_list(page, search) {
    // setLoader(true)
    try {
      const params = {
        page: page,
        search: search
      }
      const response = await helprService.UserList(params)
      if (response?.status === 200) {
        setUserData(response?.data?.data)
        setTotalItems(response?.data?.total_count)
        // setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // setLoader(false)
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.detail ? error?.response?.data?.detail : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  /* pagination */
  function handlePageChange(pageNumber) {
    setPage(pageNumber);
    user_list(pageNumber, search)
  }

  /* search */
  function handleUpdateSearch(search) {
    setSearch(search);
    setPage(1);
    user_list(page, search)
  }
  /* user disable api */

  async function disableUser(userdata) {
    try {
      const params = {
        userId: userdata.id,
      }
      setLoader(true)
      const response = await helprService.userStatus(params)
      if (response.status === 200) {
        setLoader(false)
        console.log("response------->",response);
        swal("Sucess",response?.data?.detail,"success")
        user_list(page, search)
      }
    }
    catch (error) {
      console.log("Error", error)
      setLoader(false)
      swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
    }


  }

  return (
    <>
      <div className="common-spacing">
      <div className="dashboard-main-area">
        {loader === true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={11}>
                <Row className="justify-content-between">
                  <Col md={6} className="d-flex align-items-center">
                    <h2 className="align-self-center m-0">User Management</h2>
                    <div className="excessive-usge-top-area-left main ms-4">
                        <label>Use Gemini for Chat</label>
                        <Button bsStyle="default" className="switch-design-btn">
                          <Switch
                            onChange={(event) => {
                              swal({
                                title: 'Are you sure?',
                                text: `You want to use ${event ? 'Gemini' : 'ChatGPT'} for chat?`,
                                icon: 'warning',
                                buttons: ['Cancel', 'Yes'],
                                dangerMode: true,
                              }).then((confirmed) => {
                                if (confirmed) {
                                  updateGeminiConfig(event);
                                }
                              })
                            }}
                            checked={enableGemini}
                          />
                        </Button>
                      </div>
                  </Col>

                  <Col md={6} className="search-area">
                    <div className="excessive-usge-top-area">
                    
                      <div className="excessive-search-area">
                        <label>Search</label>
                        <form
                          autoComplete="off"
                          onSubmit={(event) => {
                            event.preventDefault();
                          }}
                        >
                          <input
                            type="text"
                            className="form-control inner-input"
                            placeholder="Search by email or name"
                            onChange={(e) => handleUpdateSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                              }
                            }}
                          />
                          <i
                            className="fa fa-search"
                            aria-hidden="true"
                            onClick={() => user_list(page, search)}
                          ></i>
                          <i className="fa fa-close d-none" aria-hidden="true"></i>
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={11}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        userData?.length > 0 ? (
                          userData?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{(page - 1) * 10 + (index + 1)}</td>
                                <td>{data?.profile_image ? <img src={data?.profile_image} alt="profile_photo" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="profile_photo" height={50} width={50} className="rounded-circle" />} </td>
                                <td>{data?.name ? <>{data?.name}{" "}</> : <>NA</>}</td>
                                <td>{data?.email ? <>{data?.email}</> : <>NA</>}</td>
                                <td className="user-tooltip">
                                 <span role="button" onClick={() => navigate(`/user/${data.id}`)}>
                                 <OverlayTrigger placement="top" overlay={viewTooltip()}>
                                  <i className="fa fa-eye" aria-hidden="true"></i>
                                  </OverlayTrigger></span>
                                  <OverlayTrigger placement="top" overlay={getTooltip(data.is_active)}>
                                    <Button bsStyle="default">
                                      
                                    <Switch
                                    onChange={() => {
                                      const action = data.is_active ? 'disable' : 'enable';
                                      const buttonLabel = data.is_active ? 'Yes, disable' : 'Yes, enable';
                                      swal({
                                        title: 'Are you sure?',
                                        text: `You want to ${action} this user.`,
                                        icon: 'warning',
                                        buttons: ['Cancel', buttonLabel],
                                        dangerMode: true,
                                      }).then((confirmed) => {
                                        if (confirmed) {
                                          disableUser(data)
                                        }
                                      })
                                    }}
                                    checked={data.is_active}
                                  />
                                    </Button>
                                  </OverlayTrigger>
                                 
                                </td>
                                {/* <td role="button" onClick={() => navigate(`/user/${data.id}`)}>View</td> */}
                              </tr>
                            )
                          })) : (
                          <tr className="no-dat-found-box">
                            <td colSpan={10}>
                              <img
                                src={
                                  require("../../assets/images/no-data.svg")
                                    .default
                                }
                                alt="da"
                              />
                              <h3 className="text-center text-light">
                                No data found
                              </h3>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                  {userData.length > 0 && totalItems > 10 ?
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                    : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      </div>
 
    </>
  );
};
