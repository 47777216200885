import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { object, string } from "yup";
import { helprService } from "../../service/api.service";
import { InlineLoader } from "../../components/loader";
import { setItem, getItem } from "../../util/localStorage";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../../components/loader";
export const Login = () => {
  const [showpassword, setShowPasword] = useState(false)
  const loginObj = {
    email: "",
    password: "",
    role: "Admin"
  };
  const [loader, setLoader] = useState(false);

  const requestFormSchema = object({
    email: string().email("Please enter a valid email address.").required("Email is required.").trim(),
    password: string().required("Password is required.").trim(),
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (getItem("accessToken")) {
      navigate("/user-list");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function handleFormSubmit(value) {
    setLoader(true);
    try {
      const data={
        "email":value.email,
        "password":value.password,
        "role":"admin"
      }
      let response = await helprService.login(data);
      if ((response?.status === 200) && (response?.data?.data)) {
        setItem("accessToken", response?.data?.token);
        setLoader(false);
        localStorage.setItem("login", true)
        // swal("Success","You have successfully logged in.", "success")
        navigate("/user-list");
      }
    } catch (error) {
      setLoader(false);
      if(error?.response?.status === 400){
        console.log("error",error)
        swal({icon:"error", text: error?.response?.data?.detail, button: "OK", }).then((value) => {
          console.log('OK button clicked after error alert')
        })
      }else{
        swal({icon:"error", text: error?.response?.data?.detail ? error?.response?.data?.message: error?.detail, button: "OK", }).then((value) => {
          console.log('OK button clicked after error alert')
        })
      }
      
    }
  }

  function FormikErrorMessage(props) {
    return (
      <label id="uname-error" className="text-danger" for={props.for}>
        {props.message}
      </label>
    );
  }

  return (
    <>
     
      <div className="login-area">
      {loader ? <ThreeDotSpinner className="loginPageLoader" /> : ""}
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
            <div className="text-center">
              <img src={require("../../assets/images/logo.png")} alt="" className="logo-outer" />
              </div>
              <div className="logo-top">
              </div>
              <div className="login-content-area">
                <h2>Login</h2>
                <p>Please enter your email address and password.</p>
                <Formik
                  initialValues={loginObj}
                  validationSchema={requestFormSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                        setLoader(true);
                        handleFormSubmit(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                          <FormikErrorMessage
                            for="email"
                            message={errors.email}
                          />
                        ) : null}
                      </Form.Group>
                      <Form.Group className="mb-3 position-relative">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type={showpassword ? "text":"password"}
                          placeholder="Enter Password"
                          id="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          
                        />
                          {!showpassword ? <i className="fa-solid fa-eye-slash eye-icon" onClick={(e) => setShowPasword(true)}></i>
                      :
                      <i className="fa-solid fa-eye eye-icon" onClick={(e) => setShowPasword(false)}></i>}
                        {errors.password && touched.password ? (
                          <FormikErrorMessage
                            for="password"
                            message={errors.password}
                          />
                        ) : null}
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        {loader ? <InlineLoader /> : "Login"}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
        
      </div>
    </>
  );
};
